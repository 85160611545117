import React from 'react';

import Typography from '@material-ui/core/Typography';

import {withContext} from './../contexts/ApplicationContext';
class MainPageComponent extends React.Component {
    constructor(props){
        super(props);
        
        this.state = {numbers: []}

        this.randomValue = this.randomValue.bind(this);

        setInterval(() => {
            this.setState(previousState => ({
                numbers: [...previousState.numbers, this.randomValue(1, 100000000)]
            }));
            //console.log(this.state.numbers);
        }, 1000)
        
    }

    randomValue(min, max){
        return min + Math.random() * (max - min);
    }

    render(){
        return this.state.numbers.map(x => <Typography variant="h6">{x}</Typography>);
    }
}

export default withContext(MainPageComponent);