import React from "react";
import ReactDOM from 'react-dom';


import XilentNavigationBar from "./components/NavBar";
import {
  ApplicationContext,
  LanguageContext
} from "./contexts/ApplicationContext";
import { withStyles, createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Grid, Paper, Typography } from "@material-ui/core/";
import MenuComponent, { MenuItems } from "./components/MenuComponent";
import { Switch, Route, Redirect } from "react-router-dom";

import StatisticsPage from "./pages/StatisticsPage";
import MainPageComponent from "./pages/MainPageComponent";
import ProgressLoader from "./components/ProgressLoader";
import Backdrop from '@material-ui/core/Backdrop';
const styles = theme => ({
  root: {
    flexGrow: 1
  },
  control: {
    padding: theme.spacing(1),
    flexGrow: 1
  },
  content: {
    flexGrow: 1
  },
});

const theme = createMuiTheme({
  palette: {
    type: "dark"
  }
});

const pressedKeys = {};

class XilentApplication extends React.Component {
  constructor(props) {
    super(props);
    this.state = {isOpened: false, selectedPage: MenuItems[0], isLoading: true };

    new Promise(resolve => setTimeout(resolve, 0)).then(() => {
      const loaderElement = document.getElementById("loader");

      ReactDOM.render(<Backdrop open={true} classes={{
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      }}>
        <ProgressLoader/>
      </Backdrop>, loaderElement);
      console.log(loaderElement);
      console.log(loaderElement.firstChild);

      setTimeout(() => { loaderElement.firstChild.open = false; this.setState({isLoading: false}) }, 5000);
    });

  }

  componentDidMount() {
    this.setState({ isLoading: false });
  }

  handleKeyPress = event => {
    if(pressedKeys[event.keyCode]) return;
    pressedKeys[event.keyCode] = true;
    console.log(pressedKeys);
    if (pressedKeys[17] && pressedKeys[18] && pressedKeys[77]) {
      this.setState({isOpened: !this.state.isOpened});
    }
  };

  handleKeyUp = event => {
    delete pressedKeys[event.keyCode];
  };

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyPress);
    document.addEventListener("keyup", this.handleKeyUp);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPress);
    document.removeEventListener("keyup", this.handleKeyUp);
  }

  updateValue = (key, val) => {
    this.setState({ [key]: val });
    console.log(`Changed ${key} to ${val}`);
    console.log(this.props.history);
  };

  render() {
    if(this.state.isLoading) return null;
    const { classes } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <ApplicationContext.Provider
          value={{ state: this.state, updateValue: this.updateValue }}>
          <Grid container className={classes.root}>
            <Grid container>
              <XilentNavigationBar />
            </Grid>

            <MenuComponent />

            <Paper elevation={1} className={classes.content}>
              <Grid container className={classes.control}>
                <Switch>
                  { MenuItems.map(item => <Route path={item.urlRoute} children={item.model}/>) }


                  <Route exact path="/" model>
                    <Redirect to={MenuItems[0].urlRoute}/>
                  </Route>
                  <Route path="*">
                    PAGE NOT FOUND!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                  </Route>
                </Switch>
              </Grid>
            </Paper>
          </Grid>
        </ApplicationContext.Provider>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(XilentApplication);
