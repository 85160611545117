import React from "react";

import { withContext } from "../contexts/ApplicationContext";

import {
  withStyles,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  AppBar,
  Toolbar,
  IconButton,
  Typography
} from "@material-ui/core/";

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { ArrowForwardIos, Menu } from "@material-ui/icons/";
import { Redirect } from "react-router-dom";



const styles = theme => ({
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  paper: {
    background: "#263238"
  }
});

export const MenuItems = [
  {
    title: "Main",
    urlRoute: "/main",
    model: <div>SUKA!</div>
  },
  {
    title: "Statistics",
    urlRoute: "/statistics",
    model: <div>SUKA!</div>
  },
  {
    title: "Issues",
    urlRoute: "/issues",
    model: <div>SUKA!</div>
  },
  {
    title: "Credits",
    urlRoute: "/credits",
    model: <div>SUKA!</div>
  }
];

class MenuComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      url: "/"
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.menuItemSelected = this.menuItemSelected.bind(this);
  }

  toggleMenu(status) {
    this.props.context.updateValue("isOpened", status);
  }

  menuItemSelected(item) {
    this.setState({
      redirect: true,
      url: item.urlRoute
    });
    this.toggleMenu(false);
    this.props.context.updateValue("selectedPage", item);
  }

  render() {
    const { classes } = this.props;

    if (this.state.redirect) {
      this.setState({ redirect: false });
      return <Redirect to={this.state.url} />;
    }

    return (
      <SwipeableDrawer
        classes={{ paper: classes.paper }}
        open={this.props.context.state.isOpened}
      >
        <AppBar position="static">
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
            >
              {this.props.context.state.isOpened 
              ? (<ArrowBackIcon onClick={() => this.toggleMenu(false)} />) 
              : (<Menu onClick={() => this.toggleMenu(true)} />)
                }
            </IconButton>

            <Typography variant="h6" className={{ title: { flexGrow: 1 }}}>
              Menu
            </Typography>

          </Toolbar>
        </AppBar>

        <List>
          {MenuItems.map(item => (
            <ListItem
              button
              key={item.title}
              onClick={() => this.menuItemSelected(item)}
            >
              <ListItemIcon>
                <ArrowForwardIos />
              </ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItem>
          ))}
        </List>
      </SwipeableDrawer>
    );
  }
}

export default withStyles(styles)(withContext(MenuComponent));
