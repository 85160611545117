import React from "react";

import { Grid, Typography } from "@material-ui/core";

import {withContext} from './../contexts/ApplicationContext';
import './ProgressLoader.less';

class ProgressLoader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        spinnersCount: 4
    }

  }

  render() {
const spinnersCount = 5;
    const spinners = [...Array(spinnersCount).keys()];
    return (
      <div class="loader blue-text">
          <Typography variant="h3" class="loader-text">{this.props.currentProgress}</Typography>
        {spinners.map((value, index) => {
          return <div key={index} class={"loader-" + value}></div>;
        })}
        <div class="loader-progress"></div>
      </div>
    );
  }
}

export default withContext(ProgressLoader);
