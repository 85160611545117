import React from "react";

const ApplicationContext = React.createContext({ isOpened: false });

const withContext = Component => {
  return props => (
    <ApplicationContext.Consumer>
      { context => (
        <Component {...props} context={context} />
      )}
    </ApplicationContext.Consumer>
  );
};

export { ApplicationContext, withContext };
