import React from 'react';

import { ResponsiveCalendar } from '@nivo/calendar';

import {withContext} from './../contexts/ApplicationContext';

class StatisticsPage extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        return <div style={{height: 500, width: 400 }}> <MyResponsiveCalendar /></div>
    }
}

function customizedDate(){
    return new Date().setFullYear(2018, 2, 5);
}

const MyResponsiveCalendar = () => (
    <ResponsiveCalendar
        data={chartData}
        from="2015-03-01"
        to="2016-07-12"
        align="left"
        emptyColor="#eeeeee"
        colors={[ '#61cdbb', '#97e3d5', '#e8c1a0', '#f47560' ]}
        minValue="auto"
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        monthBorderWidth={0}
        monthBorderColor="#cd1818"
        dayBorderColor="#ffffff"
        legends={[
            {
                anchor: 'bottom-right',
                direction: 'row',
                translateY: 36,
                itemCount: 4,
                itemWidth: 42,
                itemHeight: 36,
                itemsSpacing: 14,
                itemDirection: 'right-to-left'
            }
        ]}
    />
)

export default withContext(StatisticsPage);




const chartData = [
    {
      "day": "2015-06-02",
      "value": 284
    },
    {
      "day": "2018-06-14",
      "value": 49
    },
    {
      "day": "2016-08-03",
      "value": 342
    },
    {
      "day": "2015-05-17",
      "value": 36
    },
    {
      "day": "2016-07-31",
      "value": 131
    },
    {
      "day": "2015-06-21",
      "value": 290
    },
    {
      "day": "2015-05-03",
      "value": 91
    },
    {
      "day": "2015-08-19",
      "value": 384
    },
    {
      "day": "2016-08-08",
      "value": 220
    },
    {
      "day": "2016-07-02",
      "value": 111
    },
    {
      "day": "2016-12-25",
      "value": 36
    },
    {
      "day": "2017-03-27",
      "value": 375
    },
    {
      "day": "2017-04-01",
      "value": 344
    },
    {
      "day": "2017-02-12",
      "value": 380
    },
    {
      "day": "2016-11-18",
      "value": 40
    },
    {
      "day": "2016-04-14",
      "value": 156
    },
    {
      "day": "2016-10-01",
      "value": 400
    },
    {
      "day": "2018-01-16",
      "value": 248
    },
    {
      "day": "2018-04-08",
      "value": 333
    },
    {
      "day": "2017-04-29",
      "value": 376
    },
    {
      "day": "2017-07-13",
      "value": 225
    },
    {
      "day": "2017-03-23",
      "value": 73
    },
    {
      "day": "2017-02-18",
      "value": 232
    },
    {
      "day": "2016-08-16",
      "value": 186
    },
    {
      "day": "2016-03-11",
      "value": 124
    },
    {
      "day": "2016-02-28",
      "value": 210
    },
    {
      "day": "2015-07-31",
      "value": 152
    },
    {
      "day": "2018-05-03",
      "value": 323
    },
    {
      "day": "2017-05-02",
      "value": 365
    },
    {
      "day": "2015-11-05",
      "value": 89
    },
    {
      "day": "2016-12-05",
      "value": 288
    },
    {
      "day": "2017-11-03",
      "value": 192
    },
    {
      "day": "2016-03-24",
      "value": 248
    },
    {
      "day": "2018-08-08",
      "value": 262
    },
    {
      "day": "2016-06-14",
      "value": 218
    },
    {
      "day": "2015-07-18",
      "value": 352
    },
    {
      "day": "2015-11-26",
      "value": 379
    },
    {
      "day": "2017-09-26",
      "value": 242
    },
    {
      "day": "2018-05-29",
      "value": 150
    },
    {
      "day": "2015-11-28",
      "value": 140
    },
    {
      "day": "2018-02-07",
      "value": 392
    },
    {
      "day": "2016-06-02",
      "value": 254
    },
    {
      "day": "2016-11-14",
      "value": 376
    },
    {
      "day": "2016-07-27",
      "value": 339
    },
    {
      "day": "2016-11-23",
      "value": 243
    },
    {
      "day": "2015-10-04",
      "value": 238
    },
    {
      "day": "2016-12-28",
      "value": 290
    },
    {
      "day": "2015-04-03",
      "value": 187
    },
    {
      "day": "2016-05-31",
      "value": 74
    },
    {
      "day": "2017-10-14",
      "value": 86
    },
    {
      "day": "2017-06-11",
      "value": 376
    },
    {
      "day": "2016-12-22",
      "value": 399
    },
    {
      "day": "2015-07-08",
      "value": 387
    },
    {
      "day": "2017-09-09",
      "value": 250
    },
    {
      "day": "2018-07-23",
      "value": 55
    },
    {
      "day": "2017-03-11",
      "value": 377
    },
    {
      "day": "2017-08-04",
      "value": 388
    },
    {
      "day": "2016-02-27",
      "value": 15
    },
    {
      "day": "2015-09-12",
      "value": 334
    },
    {
      "day": "2015-05-27",
      "value": 25
    },
    {
      "day": "2018-02-19",
      "value": 39
    },
    {
      "day": "2017-11-04",
      "value": 180
    },
    {
      "day": "2016-06-10",
      "value": 328
    },
    {
      "day": "2016-10-30",
      "value": 123
    },
    {
      "day": "2016-08-01",
      "value": 208
    },
    {
      "day": "2015-05-01",
      "value": 135
    },
    {
      "day": "2016-10-07",
      "value": 6
    },
    {
      "day": "2016-04-07",
      "value": 333
    },
    {
      "day": "2017-01-23",
      "value": 200
    },
    {
      "day": "2016-09-06",
      "value": 103
    },
    {
      "day": "2017-07-02",
      "value": 28
    },
    {
      "day": "2016-05-23",
      "value": 316
    },
    {
      "day": "2017-08-22",
      "value": 358
    },
    {
      "day": "2016-12-11",
      "value": 261
    },
    {
      "day": "2017-05-19",
      "value": 75
    },
    {
      "day": "2015-06-29",
      "value": 227
    },
    {
      "day": "2018-01-08",
      "value": 48
    },
    {
      "day": "2016-04-04",
      "value": 149
    },
    {
      "day": "2018-02-09",
      "value": 64
    },
    {
      "day": "2017-06-05",
      "value": 191
    },
    {
      "day": "2018-01-30",
      "value": 336
    },
    {
      "day": "2015-11-29",
      "value": 250
    },
    {
      "day": "2017-12-05",
      "value": 130
    },
    {
      "day": "2015-12-16",
      "value": 315
    },
    {
      "day": "2015-06-12",
      "value": 377
    },
    {
      "day": "2017-09-06",
      "value": 176
    },
    {
      "day": "2016-05-30",
      "value": 164
    },
    {
      "day": "2015-10-01",
      "value": 68
    },
    {
      "day": "2017-11-21",
      "value": 222
    },
    {
      "day": "2015-04-06",
      "value": 95
    },
    {
      "day": "2015-12-03",
      "value": 392
    },
    {
      "day": "2015-07-27",
      "value": 325
    },
    {
      "day": "2018-03-29",
      "value": 286
    },
    {
      "day": "2016-07-13",
      "value": 219
    },
    {
      "day": "2017-08-18",
      "value": 380
    },
    {
      "day": "2018-04-13",
      "value": 286
    },
    {
      "day": "2017-06-07",
      "value": 165
    },
    {
      "day": "2017-12-23",
      "value": 89
    },
    {
      "day": "2015-11-27",
      "value": 193
    },
    {
      "day": "2017-04-17",
      "value": 220
    },
    {
      "day": "2017-01-28",
      "value": 276
    },
    {
      "day": "2016-04-29",
      "value": 321
    },
    {
      "day": "2017-08-06",
      "value": 276
    },
    {
      "day": "2015-04-13",
      "value": 369
    },
    {
      "day": "2018-06-02",
      "value": 126
    },
    {
      "day": "2015-08-17",
      "value": 46
    },
    {
      "day": "2016-03-17",
      "value": 57
    },
    {
      "day": "2016-02-23",
      "value": 385
    },
    {
      "day": "2018-04-11",
      "value": 276
    },
    {
      "day": "2016-01-24",
      "value": 238
    },
    {
      "day": "2017-08-11",
      "value": 251
    },
    {
      "day": "2018-07-28",
      "value": 190
    },
    {
      "day": "2016-05-02",
      "value": 6
    },
    {
      "day": "2018-04-03",
      "value": 376
    },
    {
      "day": "2016-04-02",
      "value": 365
    },
    {
      "day": "2017-12-28",
      "value": 103
    },
    {
      "day": "2015-07-10",
      "value": 229
    },
    {
      "day": "2015-08-18",
      "value": 242
    },
    {
      "day": "2015-09-13",
      "value": 352
    },
    {
      "day": "2017-07-19",
      "value": 24
    },
    {
      "day": "2016-12-09",
      "value": 266
    },
    {
      "day": "2015-10-09",
      "value": 55
    },
    {
      "day": "2017-01-21",
      "value": 329
    },
    {
      "day": "2018-02-12",
      "value": 143
    },
    {
      "day": "2016-11-11",
      "value": 14
    },
    {
      "day": "2017-07-11",
      "value": 212
    },
    {
      "day": "2015-07-23",
      "value": 320
    },
    {
      "day": "2015-12-12",
      "value": 389
    },
    {
      "day": "2017-10-22",
      "value": 141
    },
    {
      "day": "2015-04-14",
      "value": 165
    },
    {
      "day": "2016-04-10",
      "value": 252
    },
    {
      "day": "2018-01-28",
      "value": 351
    },
    {
      "day": "2016-03-07",
      "value": 180
    },
    {
      "day": "2015-10-28",
      "value": 335
    },
    {
      "day": "2018-04-04",
      "value": 125
    },
    {
      "day": "2015-11-21",
      "value": 227
    },
    {
      "day": "2015-05-22",
      "value": 193
    },
    {
      "day": "2018-07-16",
      "value": 251
    },
    {
      "day": "2017-09-20",
      "value": 92
    },
    {
      "day": "2016-08-09",
      "value": 16
    },
    {
      "day": "2018-05-24",
      "value": 25
    },
    {
      "day": "2016-11-20",
      "value": 41
    },
    {
      "day": "2016-07-29",
      "value": 386
    },
    {
      "day": "2017-08-15",
      "value": 94
    },
    {
      "day": "2017-06-28",
      "value": 195
    },
    {
      "day": "2017-02-13",
      "value": 24
    },
    {
      "day": "2016-05-21",
      "value": 313
    },
    {
      "day": "2018-04-06",
      "value": 397
    },
    {
      "day": "2017-10-20",
      "value": 50
    },
    {
      "day": "2017-08-19",
      "value": 197
    },
    {
      "day": "2018-02-06",
      "value": 212
    },
    {
      "day": "2018-01-01",
      "value": 263
    },
    {
      "day": "2016-05-14",
      "value": 182
    },
    {
      "day": "2016-01-22",
      "value": 255
    },
    {
      "day": "2016-10-10",
      "value": 227
    },
    {
      "day": "2016-08-20",
      "value": 267
    },
    {
      "day": "2016-09-26",
      "value": 214
    },
    {
      "day": "2017-02-17",
      "value": 400
    },
    {
      "day": "2015-11-06",
      "value": 109
    },
    {
      "day": "2015-06-18",
      "value": 211
    },
    {
      "day": "2017-11-23",
      "value": 32
    },
    {
      "day": "2017-07-20",
      "value": 211
    },
    {
      "day": "2016-07-25",
      "value": 135
    },
    {
      "day": "2018-08-07",
      "value": 391
    },
    {
      "day": "2015-09-29",
      "value": 264
    },
    {
      "day": "2017-11-16",
      "value": 282
    },
    {
      "day": "2016-02-21",
      "value": 42
    },
    {
      "day": "2016-05-29",
      "value": 191
    },
    {
      "day": "2016-02-17",
      "value": 296
    },
    {
      "day": "2016-04-18",
      "value": 95
    },
    {
      "day": "2017-08-09",
      "value": 202
    },
    {
      "day": "2015-07-28",
      "value": 244
    },
    {
      "day": "2015-04-17",
      "value": 244
    },
    {
      "day": "2018-07-06",
      "value": 371
    },
    {
      "day": "2017-05-03",
      "value": 129
    },
    {
      "day": "2016-03-18",
      "value": 161
    },
    {
      "day": "2015-10-08",
      "value": 152
    },
    {
      "day": "2016-10-04",
      "value": 389
    },
    {
      "day": "2015-10-31",
      "value": 186
    },
    {
      "day": "2016-09-08",
      "value": 211
    },
    {
      "day": "2017-11-10",
      "value": 146
    },
    {
      "day": "2016-08-29",
      "value": 213
    },
    {
      "day": "2015-04-07",
      "value": 53
    },
    {
      "day": "2015-09-17",
      "value": 222
    },
    {
      "day": "2016-01-27",
      "value": 167
    },
    {
      "day": "2016-06-17",
      "value": 41
    },
    {
      "day": "2018-02-17",
      "value": 335
    },
    {
      "day": "2016-05-12",
      "value": 81
    },
    {
      "day": "2016-03-22",
      "value": 167
    },
    {
      "day": "2016-05-07",
      "value": 239
    },
    {
      "day": "2017-03-22",
      "value": 166
    },
    {
      "day": "2016-09-23",
      "value": 152
    },
    {
      "day": "2015-11-11",
      "value": 152
    },
    {
      "day": "2018-04-09",
      "value": 321
    },
    {
      "day": "2015-07-17",
      "value": 344
    },
    {
      "day": "2017-05-27",
      "value": 258
    },
    {
      "day": "2018-01-06",
      "value": 302
    },
    {
      "day": "2017-09-01",
      "value": 314
    },
    {
      "day": "2017-01-05",
      "value": 396
    },
    {
      "day": "2015-04-16",
      "value": 166
    },
    {
      "day": "2015-12-06",
      "value": 1
    },
    {
      "day": "2017-11-22",
      "value": 18
    },
    {
      "day": "2016-04-01",
      "value": 304
    },
    {
      "day": "2018-01-26",
      "value": 29
    },
    {
      "day": "2015-05-12",
      "value": 179
    },
    {
      "day": "2016-07-16",
      "value": 348
    },
    {
      "day": "2017-06-13",
      "value": 87
    },
    {
      "day": "2015-06-26",
      "value": 24
    },
    {
      "day": "2017-12-08",
      "value": 183
    },
    {
      "day": "2016-11-08",
      "value": 25
    },
    {
      "day": "2016-06-06",
      "value": 85
    },
    {
      "day": "2017-03-01",
      "value": 71
    },
    {
      "day": "2018-05-01",
      "value": 158
    },
    {
      "day": "2016-03-04",
      "value": 383
    },
    {
      "day": "2018-03-14",
      "value": 207
    },
    {
      "day": "2017-10-09",
      "value": 103
    },
    {
      "day": "2015-06-23",
      "value": 20
    },
    {
      "day": "2015-12-07",
      "value": 71
    },
    {
      "day": "2018-07-11",
      "value": 48
    },
    {
      "day": "2017-04-26",
      "value": 359
    },
    {
      "day": "2015-09-01",
      "value": 44
    },
    {
      "day": "2015-10-29",
      "value": 231
    },
    {
      "day": "2015-10-18",
      "value": 397
    },
    {
      "day": "2018-01-13",
      "value": 26
    },
    {
      "day": "2016-08-28",
      "value": 269
    },
    {
      "day": "2017-08-30",
      "value": 397
    },
    {
      "day": "2018-08-02",
      "value": 157
    },
    {
      "day": "2016-03-03",
      "value": 48
    },
    {
      "day": "2016-09-19",
      "value": 337
    },
    {
      "day": "2016-06-20",
      "value": 109
    },
    {
      "day": "2016-06-21",
      "value": 182
    },
    {
      "day": "2016-05-03",
      "value": 195
    },
    {
      "day": "2018-02-02",
      "value": 230
    },
    {
      "day": "2018-03-22",
      "value": 32
    },
    {
      "day": "2017-06-04",
      "value": 20
    },
    {
      "day": "2017-05-14",
      "value": 86
    },
    {
      "day": "2017-10-28",
      "value": 341
    },
    {
      "day": "2017-09-12",
      "value": 217
    },
    {
      "day": "2016-10-11",
      "value": 265
    },
    {
      "day": "2017-06-20",
      "value": 358
    },
    {
      "day": "2016-09-14",
      "value": 387
    },
    {
      "day": "2017-01-03",
      "value": 328
    },
    {
      "day": "2015-11-25",
      "value": 284
    },
    {
      "day": "2017-11-01",
      "value": 100
    },
    {
      "day": "2018-01-22",
      "value": 282
    },
    {
      "day": "2016-06-03",
      "value": 89
    },
    {
      "day": "2018-01-12",
      "value": 275
    },
    {
      "day": "2018-01-20",
      "value": 326
    },
    {
      "day": "2016-03-20",
      "value": 295
    },
    {
      "day": "2018-05-16",
      "value": 160
    },
    {
      "day": "2016-01-08",
      "value": 128
    },
    {
      "day": "2015-12-10",
      "value": 369
    },
    {
      "day": "2015-11-08",
      "value": 73
    },
    {
      "day": "2016-01-14",
      "value": 159
    },
    {
      "day": "2017-02-11",
      "value": 296
    },
    {
      "day": "2016-02-04",
      "value": 209
    },
    {
      "day": "2017-02-10",
      "value": 309
    },
    {
      "day": "2016-04-25",
      "value": 298
    },
    {
      "day": "2018-01-27",
      "value": 180
    },
    {
      "day": "2016-05-18",
      "value": 387
    },
    {
      "day": "2018-04-24",
      "value": 276
    },
    {
      "day": "2015-07-21",
      "value": 298
    },
    {
      "day": "2015-06-07",
      "value": 53
    },
    {
      "day": "2017-11-07",
      "value": 382
    },
    {
      "day": "2017-10-26",
      "value": 209
    },
    {
      "day": "2018-01-11",
      "value": 8
    },
    {
      "day": "2016-08-31",
      "value": 352
    },
    {
      "day": "2018-03-13",
      "value": 35
    },
    {
      "day": "2016-02-19",
      "value": 111
    },
    {
      "day": "2017-04-16",
      "value": 209
    },
    {
      "day": "2017-10-03",
      "value": 378
    },
    {
      "day": "2017-01-11",
      "value": 87
    },
    {
      "day": "2017-09-23",
      "value": 108
    },
    {
      "day": "2016-11-19",
      "value": 275
    },
    {
      "day": "2016-06-15",
      "value": 319
    },
    {
      "day": "2015-07-05",
      "value": 281
    },
    {
      "day": "2015-12-20",
      "value": 239
    },
    {
      "day": "2015-06-17",
      "value": 220
    },
    {
      "day": "2018-03-12",
      "value": 327
    },
    {
      "day": "2017-01-07",
      "value": 28
    },
    {
      "day": "2015-07-19",
      "value": 257
    },
    {
      "day": "2017-04-25",
      "value": 233
    },
    {
      "day": "2015-06-08",
      "value": 60
    },
    {
      "day": "2015-06-19",
      "value": 17
    },
    {
      "day": "2017-04-11",
      "value": 276
    },
    {
      "day": "2017-12-17",
      "value": 260
    },
    {
      "day": "2015-06-09",
      "value": 185
    },
    {
      "day": "2017-04-20",
      "value": 178
    },
    {
      "day": "2015-11-09",
      "value": 13
    },
    {
      "day": "2015-06-27",
      "value": 121
    },
    {
      "day": "2016-04-11",
      "value": 330
    },
    {
      "day": "2016-09-21",
      "value": 279
    },
    {
      "day": "2017-11-13",
      "value": 353
    },
    {
      "day": "2017-04-15",
      "value": 367
    },
    {
      "day": "2018-06-08",
      "value": 182
    },
    {
      "day": "2015-12-30",
      "value": 11
    },
    {
      "day": "2016-03-05",
      "value": 307
    },
    {
      "day": "2018-03-17",
      "value": 307
    },
    {
      "day": "2018-03-08",
      "value": 174
    },
    {
      "day": "2015-05-23",
      "value": 26
    },
    {
      "day": "2018-03-04",
      "value": 374
    },
    {
      "day": "2017-11-26",
      "value": 309
    },
    {
      "day": "2017-09-08",
      "value": 49
    },
    {
      "day": "2015-09-28",
      "value": 381
    },
    {
      "day": "2017-11-09",
      "value": 65
    },
    {
      "day": "2016-11-27",
      "value": 197
    },
    {
      "day": "2017-08-02",
      "value": 249
    },
    {
      "day": "2015-08-02",
      "value": 297
    },
    {
      "day": "2017-07-15",
      "value": 351
    },
    {
      "day": "2017-08-12",
      "value": 93
    },
    {
      "day": "2016-01-12",
      "value": 278
    },
    {
      "day": "2015-06-13",
      "value": 120
    },
    {
      "day": "2018-01-19",
      "value": 356
    },
    {
      "day": "2018-04-20",
      "value": 242
    },
    {
      "day": "2015-07-02",
      "value": 121
    },
    {
      "day": "2016-01-16",
      "value": 285
    },
    {
      "day": "2015-08-03",
      "value": 160
    },
    {
      "day": "2018-05-17",
      "value": 377
    },
    {
      "day": "2018-04-29",
      "value": 45
    },
    {
      "day": "2015-05-02",
      "value": 174
    },
    {
      "day": "2016-04-24",
      "value": 259
    },
    {
      "day": "2015-11-15",
      "value": 125
    },
    {
      "day": "2016-06-09",
      "value": 289
    },
    {
      "day": "2017-04-21",
      "value": 336
    },
    {
      "day": "2017-03-12",
      "value": 24
    },
    {
      "day": "2017-04-09",
      "value": 102
    },
    {
      "day": "2016-07-28",
      "value": 340
    },
    {
      "day": "2016-02-11",
      "value": 311
    },
    {
      "day": "2016-07-07",
      "value": 386
    },
    {
      "day": "2015-11-24",
      "value": 53
    },
    {
      "day": "2017-09-30",
      "value": 3
    },
    {
      "day": "2018-05-23",
      "value": 25
    },
    {
      "day": "2017-08-14",
      "value": 362
    },
    {
      "day": "2017-11-15",
      "value": 138
    },
    {
      "day": "2015-07-03",
      "value": 128
    },
    {
      "day": "2015-05-04",
      "value": 16
    },
    {
      "day": "2018-03-02",
      "value": 203
    },
    {
      "day": "2017-02-07",
      "value": 159
    },
    {
      "day": "2016-10-24",
      "value": 112
    },
    {
      "day": "2018-05-21",
      "value": 313
    },
    {
      "day": "2015-09-02",
      "value": 119
    },
    {
      "day": "2016-06-29",
      "value": 325
    },
    {
      "day": "2016-12-03",
      "value": 345
    },
    {
      "day": "2016-12-01",
      "value": 230
    },
    {
      "day": "2017-07-10",
      "value": 319
    },
    {
      "day": "2016-02-09",
      "value": 280
    },
    {
      "day": "2018-01-24",
      "value": 183
    },
    {
      "day": "2016-10-16",
      "value": 228
    },
    {
      "day": "2017-12-15",
      "value": 306
    },
    {
      "day": "2018-07-25",
      "value": 249
    },
    {
      "day": "2016-11-04",
      "value": 159
    },
    {
      "day": "2016-04-28",
      "value": 342
    },
    {
      "day": "2015-06-25",
      "value": 101
    },
    {
      "day": "2018-05-28",
      "value": 196
    },
    {
      "day": "2018-07-09",
      "value": 390
    },
    {
      "day": "2018-04-05",
      "value": 330
    },
    {
      "day": "2015-10-06",
      "value": 348
    },
    {
      "day": "2017-03-08",
      "value": 149
    },
    {
      "day": "2015-12-23",
      "value": 77
    },
    {
      "day": "2017-04-28",
      "value": 326
    },
    {
      "day": "2016-02-15",
      "value": 239
    },
    {
      "day": "2016-10-13",
      "value": 303
    },
    {
      "day": "2018-05-31",
      "value": 367
    },
    {
      "day": "2018-07-04",
      "value": 283
    },
    {
      "day": "2015-08-07",
      "value": 191
    },
    {
      "day": "2017-07-06",
      "value": 192
    },
    {
      "day": "2015-08-29",
      "value": 382
    },
    {
      "day": "2015-09-09",
      "value": 342
    },
    {
      "day": "2017-10-13",
      "value": 329
    },
    {
      "day": "2017-05-06",
      "value": 148
    },
    {
      "day": "2015-09-03",
      "value": 290
    },
    {
      "day": "2018-07-20",
      "value": 240
    },
    {
      "day": "2018-03-31",
      "value": 178
    },
    {
      "day": "2018-05-06",
      "value": 7
    },
    {
      "day": "2016-07-12",
      "value": 275
    },
    {
      "day": "2016-10-22",
      "value": 50
    },
    {
      "day": "2017-05-23",
      "value": 195
    },
    {
      "day": "2016-12-16",
      "value": 128
    },
    {
      "day": "2016-03-01",
      "value": 395
    },
    {
      "day": "2017-08-31",
      "value": 114
    },
    {
      "day": "2016-12-21",
      "value": 380
    },
    {
      "day": "2016-06-01",
      "value": 102
    },
    {
      "day": "2017-01-04",
      "value": 330
    },
    {
      "day": "2017-04-08",
      "value": 242
    },
    {
      "day": "2016-01-13",
      "value": 353
    },
    {
      "day": "2017-05-17",
      "value": 261
    },
    {
      "day": "2018-01-02",
      "value": 8
    },
    {
      "day": "2016-10-03",
      "value": 366
    },
    {
      "day": "2016-02-12",
      "value": 52
    },
    {
      "day": "2016-04-09",
      "value": 42
    },
    {
      "day": "2015-12-19",
      "value": 361
    },
    {
      "day": "2017-10-02",
      "value": 83
    },
    {
      "day": "2015-11-16",
      "value": 330
    },
    {
      "day": "2017-09-10",
      "value": 85
    },
    {
      "day": "2016-09-11",
      "value": 344
    },
    {
      "day": "2016-05-09",
      "value": 132
    },
    {
      "day": "2017-03-29",
      "value": 125
    },
    {
      "day": "2015-04-24",
      "value": 326
    },
    {
      "day": "2017-02-28",
      "value": 137
    },
    {
      "day": "2016-11-26",
      "value": 50
    },
    {
      "day": "2018-06-11",
      "value": 27
    },
    {
      "day": "2015-10-10",
      "value": 48
    },
    {
      "day": "2016-02-03",
      "value": 12
    },
    {
      "day": "2018-02-10",
      "value": 187
    },
    {
      "day": "2017-04-12",
      "value": 207
    },
    {
      "day": "2018-01-04",
      "value": 64
    },
    {
      "day": "2017-01-31",
      "value": 26
    },
    {
      "day": "2016-03-06",
      "value": 3
    },
    {
      "day": "2017-08-25",
      "value": 328
    },
    {
      "day": "2017-07-23",
      "value": 116
    },
    {
      "day": "2016-03-21",
      "value": 235
    },
    {
      "day": "2017-02-14",
      "value": 211
    },
    {
      "day": "2018-08-01",
      "value": 65
    },
    {
      "day": "2016-12-04",
      "value": 106
    },
    {
      "day": "2016-02-13",
      "value": 275
    },
    {
      "day": "2015-04-25",
      "value": 337
    },
    {
      "day": "2015-10-11",
      "value": 145
    },
    {
      "day": "2016-07-22",
      "value": 11
    },
    {
      "day": "2016-06-05",
      "value": 79
    },
    {
      "day": "2016-01-21",
      "value": 134
    },
    {
      "day": "2017-10-21",
      "value": 195
    },
    {
      "day": "2015-05-19",
      "value": 244
    },
    {
      "day": "2015-05-26",
      "value": 323
    },
    {
      "day": "2017-05-28",
      "value": 337
    },
    {
      "day": "2017-07-22",
      "value": 36
    },
    {
      "day": "2017-12-25",
      "value": 11
    },
    {
      "day": "2018-06-19",
      "value": 103
    },
    {
      "day": "2016-09-12",
      "value": 1
    },
    {
      "day": "2016-11-09",
      "value": 176
    },
    {
      "day": "2015-11-23",
      "value": 287
    },
    {
      "day": "2016-08-22",
      "value": 69
    },
    {
      "day": "2016-04-27",
      "value": 221
    },
    {
      "day": "2017-08-21",
      "value": 278
    },
    {
      "day": "2016-08-04",
      "value": 46
    },
    {
      "day": "2016-07-10",
      "value": 369
    },
    {
      "day": "2016-08-24",
      "value": 311
    },
    {
      "day": "2017-08-01",
      "value": 338
    },
    {
      "day": "2018-02-21",
      "value": 144
    },
    {
      "day": "2015-05-06",
      "value": 120
    },
    {
      "day": "2018-04-25",
      "value": 386
    },
    {
      "day": "2016-12-14",
      "value": 308
    },
    {
      "day": "2017-02-23",
      "value": 78
    },
    {
      "day": "2018-08-05",
      "value": 160
    },
    {
      "day": "2016-12-31",
      "value": 338
    },
    {
      "day": "2018-06-15",
      "value": 299
    },
    {
      "day": "2017-01-06",
      "value": 7
    },
    {
      "day": "2016-12-27",
      "value": 188
    },
    {
      "day": "2017-01-08",
      "value": 357
    },
    {
      "day": "2018-03-10",
      "value": 200
    },
    {
      "day": "2018-05-26",
      "value": 218
    },
    {
      "day": "2017-05-04",
      "value": 206
    },
    {
      "day": "2018-04-17",
      "value": 219
    },
    {
      "day": "2017-10-25",
      "value": 83
    },
    {
      "day": "2017-07-17",
      "value": 4
    },
    {
      "day": "2017-04-04",
      "value": 16
    },
    {
      "day": "2015-11-22",
      "value": 294
    },
    {
      "day": "2018-01-03",
      "value": 30
    },
    {
      "day": "2017-02-26",
      "value": 11
    },
    {
      "day": "2015-10-15",
      "value": 276
    },
    {
      "day": "2017-06-25",
      "value": 379
    },
    {
      "day": "2015-10-05",
      "value": 382
    },
    {
      "day": "2015-08-10",
      "value": 41
    },
    {
      "day": "2016-01-11",
      "value": 167
    },
    {
      "day": "2015-05-16",
      "value": 275
    },
    {
      "day": "2017-06-14",
      "value": 185
    },
    {
      "day": "2018-04-14",
      "value": 47
    },
    {
      "day": "2018-06-10",
      "value": 48
    },
    {
      "day": "2017-02-25",
      "value": 76
    },
    {
      "day": "2017-06-03",
      "value": 217
    },
    {
      "day": "2017-01-13",
      "value": 254
    },
    {
      "day": "2018-01-25",
      "value": 47
    },
    {
      "day": "2015-07-26",
      "value": 61
    },
    {
      "day": "2017-02-03",
      "value": 11
    },
    {
      "day": "2016-12-07",
      "value": 46
    },
    {
      "day": "2016-06-04",
      "value": 0
    },
    {
      "day": "2017-09-24",
      "value": 253
    },
    {
      "day": "2017-08-08",
      "value": 332
    },
    {
      "day": "2016-05-28",
      "value": 145
    },
    {
      "day": "2017-06-10",
      "value": 279
    },
    {
      "day": "2015-08-04",
      "value": 156
    },
    {
      "day": "2017-05-25",
      "value": 1
    },
    {
      "day": "2016-09-30",
      "value": 372
    },
    {
      "day": "2016-07-08",
      "value": 207
    },
    {
      "day": "2016-03-08",
      "value": 340
    },
    {
      "day": "2016-02-29",
      "value": 130
    },
    {
      "day": "2017-03-05",
      "value": 141
    },
    {
      "day": "2016-10-02",
      "value": 375
    },
    {
      "day": "2015-07-01",
      "value": 288
    },
    {
      "day": "2018-03-01",
      "value": 13
    },
    {
      "day": "2018-06-03",
      "value": 254
    },
    {
      "day": "2018-03-28",
      "value": 96
    },
    {
      "day": "2017-05-22",
      "value": 166
    },
    {
      "day": "2017-02-06",
      "value": 22
    },
    {
      "day": "2018-08-11",
      "value": 205
    },
    {
      "day": "2017-08-13",
      "value": 225
    },
    {
      "day": "2017-06-21",
      "value": 52
    },
    {
      "day": "2017-08-20",
      "value": 176
    },
    {
      "day": "2016-09-03",
      "value": 314
    },
    {
      "day": "2017-11-24",
      "value": 93
    },
    {
      "day": "2017-05-09",
      "value": 344
    },
    {
      "day": "2015-07-14",
      "value": 171
    },
    {
      "day": "2017-03-31",
      "value": 74
    },
    {
      "day": "2018-06-22",
      "value": 82
    },
    {
      "day": "2018-02-01",
      "value": 381
    },
    {
      "day": "2016-04-06",
      "value": 42
    },
    {
      "day": "2016-07-17",
      "value": 196
    },
    {
      "day": "2018-02-03",
      "value": 350
    },
    {
      "day": "2016-03-15",
      "value": 311
    },
    {
      "day": "2018-06-24",
      "value": 272
    },
    {
      "day": "2017-09-25",
      "value": 98
    },
    {
      "day": "2018-07-18",
      "value": 329
    },
    {
      "day": "2017-09-21",
      "value": 220
    },
    {
      "day": "2018-03-18",
      "value": 310
    },
    {
      "day": "2015-05-08",
      "value": 103
    },
    {
      "day": "2017-11-06",
      "value": 173
    },
    {
      "day": "2018-03-07",
      "value": 283
    },
    {
      "day": "2015-09-19",
      "value": 255
    },
    {
      "day": "2017-07-04",
      "value": 6
    },
    {
      "day": "2016-07-18",
      "value": 229
    },
    {
      "day": "2015-12-21",
      "value": 190
    },
    {
      "day": "2016-08-15",
      "value": 264
    },
    {
      "day": "2017-10-04",
      "value": 237
    },
    {
      "day": "2016-11-02",
      "value": 192
    },
    {
      "day": "2017-10-11",
      "value": 108
    },
    {
      "day": "2017-04-24",
      "value": 14
    },
    {
      "day": "2018-06-25",
      "value": 380
    },
    {
      "day": "2015-08-15",
      "value": 214
    },
    {
      "day": "2017-10-10",
      "value": 97
    },
    {
      "day": "2016-07-14",
      "value": 57
    },
    {
      "day": "2017-12-03",
      "value": 353
    },
    {
      "day": "2016-07-20",
      "value": 246
    },
    {
      "day": "2018-02-28",
      "value": 11
    },
    {
      "day": "2016-04-16",
      "value": 201
    },
    {
      "day": "2018-05-22",
      "value": 315
    },
    {
      "day": "2015-04-08",
      "value": 272
    },
    {
      "day": "2016-06-26",
      "value": 170
    },
    {
      "day": "2017-08-27",
      "value": 252
    },
    {
      "day": "2017-04-06",
      "value": 319
    },
    {
      "day": "2016-03-19",
      "value": 349
    },
    {
      "day": "2017-07-29",
      "value": 107
    },
    {
      "day": "2018-03-11",
      "value": 140
    },
    {
      "day": "2017-12-07",
      "value": 24
    },
    {
      "day": "2016-07-19",
      "value": 371
    },
    {
      "day": "2016-02-14",
      "value": 377
    },
    {
      "day": "2017-08-03",
      "value": 211
    },
    {
      "day": "2017-04-14",
      "value": 188
    },
    {
      "day": "2017-01-18",
      "value": 259
    },
    {
      "day": "2017-05-24",
      "value": 389
    },
    {
      "day": "2016-11-10",
      "value": 75
    },
    {
      "day": "2017-12-26",
      "value": 125
    },
    {
      "day": "2015-09-23",
      "value": 314
    },
    {
      "day": "2016-06-16",
      "value": 29
    },
    {
      "day": "2015-08-30",
      "value": 18
    },
    {
      "day": "2018-07-24",
      "value": 187
    },
    {
      "day": "2015-09-25",
      "value": 236
    },
    {
      "day": "2017-11-17",
      "value": 144
    },
    {
      "day": "2018-05-12",
      "value": 170
    },
    {
      "day": "2015-05-24",
      "value": 18
    },
    {
      "day": "2015-12-02",
      "value": 192
    },
    {
      "day": "2018-01-23",
      "value": 305
    },
    {
      "day": "2015-12-18",
      "value": 9
    },
    {
      "day": "2017-07-03",
      "value": 183
    },
    {
      "day": "2016-10-27",
      "value": 286
    },
    {
      "day": "2018-07-31",
      "value": 247
    },
    {
      "day": "2015-09-11",
      "value": 359
    },
    {
      "day": "2015-09-24",
      "value": 276
    },
    {
      "day": "2015-04-09",
      "value": 367
    },
    {
      "day": "2016-06-28",
      "value": 264
    },
    {
      "day": "2017-10-23",
      "value": 51
    },
    {
      "day": "2018-02-18",
      "value": 124
    },
    {
      "day": "2017-12-14",
      "value": 225
    },
    {
      "day": "2016-02-10",
      "value": 230
    },
    {
      "day": "2016-03-25",
      "value": 61
    },
    {
      "day": "2017-08-26",
      "value": 302
    },
    {
      "day": "2018-02-16",
      "value": 100
    },
    {
      "day": "2017-07-27",
      "value": 90
    },
    {
      "day": "2015-10-26",
      "value": 150
    },
    {
      "day": "2018-03-24",
      "value": 337
    },
    {
      "day": "2017-12-02",
      "value": 358
    },
    {
      "day": "2016-05-16",
      "value": 228
    },
    {
      "day": "2017-07-30",
      "value": 72
    },
    {
      "day": "2016-06-08",
      "value": 113
    },
    {
      "day": "2017-03-06",
      "value": 234
    },
    {
      "day": "2015-11-30",
      "value": 244
    },
    {
      "day": "2015-07-13",
      "value": 339
    },
    {
      "day": "2018-05-02",
      "value": 352
    },
    {
      "day": "2016-03-29",
      "value": 88
    },
    {
      "day": "2016-08-02",
      "value": 235
    },
    {
      "day": "2017-11-27",
      "value": 359
    },
    {
      "day": "2016-03-10",
      "value": 352
    },
    {
      "day": "2016-05-27",
      "value": 264
    },
    {
      "day": "2016-10-28",
      "value": 343
    },
    {
      "day": "2015-08-08",
      "value": 171
    },
    {
      "day": "2018-06-04",
      "value": 113
    },
    {
      "day": "2016-09-05",
      "value": 316
    },
    {
      "day": "2015-07-11",
      "value": 111
    },
    {
      "day": "2017-06-19",
      "value": 123
    },
    {
      "day": "2017-04-23",
      "value": 268
    },
    {
      "day": "2016-06-24",
      "value": 123
    },
    {
      "day": "2017-09-17",
      "value": 243
    },
    {
      "day": "2016-08-10",
      "value": 115
    },
    {
      "day": "2017-01-22",
      "value": 238
    },
    {
      "day": "2015-04-26",
      "value": 224
    },
    {
      "day": "2015-04-18",
      "value": 313
    },
    {
      "day": "2017-12-10",
      "value": 150
    },
    {
      "day": "2016-11-01",
      "value": 156
    },
    {
      "day": "2018-03-26",
      "value": 305
    },
    {
      "day": "2016-01-10",
      "value": 262
    },
    {
      "day": "2016-07-05",
      "value": 289
    },
    {
      "day": "2018-02-05",
      "value": 140
    },
    {
      "day": "2016-08-06",
      "value": 226
    },
    {
      "day": "2015-09-04",
      "value": 253
    },
    {
      "day": "2015-12-05",
      "value": 45
    },
    {
      "day": "2016-12-06",
      "value": 310
    },
    {
      "day": "2015-05-31",
      "value": 145
    },
    {
      "day": "2017-12-06",
      "value": 269
    },
    {
      "day": "2015-10-27",
      "value": 332
    },
    {
      "day": "2016-09-02",
      "value": 390
    },
    {
      "day": "2016-08-12",
      "value": 11
    },
    {
      "day": "2017-03-26",
      "value": 19
    },
    {
      "day": "2016-07-24",
      "value": 276
    },
    {
      "day": "2016-04-30",
      "value": 106
    },
    {
      "day": "2018-05-11",
      "value": 170
    },
    {
      "day": "2017-11-20",
      "value": 114
    },
    {
      "day": "2016-11-07",
      "value": 137
    },
    {
      "day": "2016-01-26",
      "value": 306
    },
    {
      "day": "2017-03-15",
      "value": 321
    },
    {
      "day": "2017-05-05",
      "value": 220
    },
    {
      "day": "2015-12-22",
      "value": 137
    },
    {
      "day": "2018-04-21",
      "value": 230
    },
    {
      "day": "2017-02-05",
      "value": 370
    },
    {
      "day": "2017-02-15",
      "value": 158
    },
    {
      "day": "2015-12-11",
      "value": 14
    },
    {
      "day": "2018-03-05",
      "value": 263
    },
    {
      "day": "2017-06-29",
      "value": 135
    },
    {
      "day": "2017-10-18",
      "value": 175
    },
    {
      "day": "2016-05-13",
      "value": 139
    },
    {
      "day": "2018-07-21",
      "value": 190
    },
    {
      "day": "2016-08-11",
      "value": 257
    },
    {
      "day": "2016-01-05",
      "value": 175
    },
    {
      "day": "2015-12-08",
      "value": 22
    },
    {
      "day": "2016-07-11",
      "value": 73
    },
    {
      "day": "2017-03-10",
      "value": 159
    },
    {
      "day": "2017-05-29",
      "value": 399
    },
    {
      "day": "2016-01-15",
      "value": 275
    },
    {
      "day": "2016-07-03",
      "value": 80
    },
    {
      "day": "2017-09-19",
      "value": 300
    },
    {
      "day": "2017-12-04",
      "value": 97
    },
    {
      "day": "2016-10-20",
      "value": 71
    },
    {
      "day": "2016-12-20",
      "value": 373
    },
    {
      "day": "2018-06-27",
      "value": 239
    },
    {
      "day": "2018-05-18",
      "value": 17
    },
    {
      "day": "2015-10-25",
      "value": 155
    },
    {
      "day": "2017-10-01",
      "value": 27
    },
    {
      "day": "2017-09-07",
      "value": 71
    },
    {
      "day": "2018-07-08",
      "value": 75
    },
    {
      "day": "2016-01-01",
      "value": 41
    },
    {
      "day": "2017-09-14",
      "value": 64
    },
    {
      "day": "2018-06-16",
      "value": 172
    },
    {
      "day": "2017-02-22",
      "value": 73
    },
    {
      "day": "2017-03-24",
      "value": 287
    },
    {
      "day": "2018-01-17",
      "value": 365
    },
    {
      "day": "2017-12-29",
      "value": 127
    },
    {
      "day": "2015-08-09",
      "value": 361
    },
    {
      "day": "2015-07-04",
      "value": 358
    },
    {
      "day": "2017-06-27",
      "value": 100
    },
    {
      "day": "2018-06-07",
      "value": 344
    },
    {
      "day": "2017-06-18",
      "value": 248
    },
    {
      "day": "2016-09-18",
      "value": 109
    },
    {
      "day": "2015-06-22",
      "value": 139
    },
    {
      "day": "2015-06-24",
      "value": 232
    },
    {
      "day": "2016-01-07",
      "value": 54
    },
    {
      "day": "2017-02-02",
      "value": 104
    },
    {
      "day": "2017-06-30",
      "value": 233
    },
    {
      "day": "2015-10-22",
      "value": 195
    },
    {
      "day": "2015-05-13",
      "value": 356
    },
    {
      "day": "2016-02-22",
      "value": 42
    },
    {
      "day": "2016-10-12",
      "value": 163
    },
    {
      "day": "2017-11-29",
      "value": 127
    },
    {
      "day": "2017-08-28",
      "value": 364
    },
    {
      "day": "2017-03-07",
      "value": 251
    },
    {
      "day": "2016-05-19",
      "value": 264
    },
    {
      "day": "2017-12-24",
      "value": 318
    },
    {
      "day": "2015-09-07",
      "value": 300
    },
    {
      "day": "2017-07-24",
      "value": 200
    },
    {
      "day": "2018-03-21",
      "value": 105
    },
    {
      "day": "2015-12-15",
      "value": 293
    },
    {
      "day": "2017-10-08",
      "value": 169
    },
    {
      "day": "2016-05-05",
      "value": 57
    },
    {
      "day": "2016-07-23",
      "value": 197
    },
    {
      "day": "2017-05-07",
      "value": 320
    },
    {
      "day": "2017-12-18",
      "value": 274
    },
    {
      "day": "2018-04-07",
      "value": 25
    },
    {
      "day": "2016-02-08",
      "value": 44
    },
    {
      "day": "2017-12-19",
      "value": 155
    },
    {
      "day": "2016-04-26",
      "value": 337
    },
    {
      "day": "2017-01-25",
      "value": 280
    },
    {
      "day": "2016-11-30",
      "value": 354
    },
    {
      "day": "2015-10-07",
      "value": 162
    },
    {
      "day": "2015-12-01",
      "value": 326
    },
    {
      "day": "2018-07-14",
      "value": 196
    },
    {
      "day": "2015-10-12",
      "value": 152
    },
    {
      "day": "2017-09-15",
      "value": 4
    },
    {
      "day": "2018-02-26",
      "value": 397
    },
    {
      "day": "2017-09-18",
      "value": 224
    },
    {
      "day": "2016-10-14",
      "value": 196
    },
    {
      "day": "2015-07-16",
      "value": 252
    },
    {
      "day": "2018-04-15",
      "value": 149
    },
    {
      "day": "2017-01-17",
      "value": 21
    },
    {
      "day": "2018-06-28",
      "value": 236
    },
    {
      "day": "2018-04-19",
      "value": 291
    },
    {
      "day": "2016-09-04",
      "value": 103
    },
    {
      "day": "2018-06-12",
      "value": 150
    },
    {
      "day": "2017-06-12",
      "value": 376
    },
    {
      "day": "2017-07-07",
      "value": 44
    },
    {
      "day": "2017-01-14",
      "value": 59
    },
    {
      "day": "2018-03-23",
      "value": 346
    },
    {
      "day": "2017-06-08",
      "value": 244
    },
    {
      "day": "2018-08-04",
      "value": 252
    },
    {
      "day": "2016-10-15",
      "value": 362
    },
    {
      "day": "2017-07-08",
      "value": 128
    },
    {
      "day": "2017-09-27",
      "value": 130
    },
    {
      "day": "2018-07-10",
      "value": 79
    },
    {
      "day": "2018-02-22",
      "value": 196
    },
    {
      "day": "2015-04-04",
      "value": 369
    },
    {
      "day": "2015-10-19",
      "value": 141
    },
    {
      "day": "2018-07-05",
      "value": 277
    },
    {
      "day": "2017-07-18",
      "value": 290
    },
    {
      "day": "2017-06-23",
      "value": 266
    },
    {
      "day": "2017-03-09",
      "value": 285
    },
    {
      "day": "2017-07-21",
      "value": 70
    },
    {
      "day": "2017-07-14",
      "value": 25
    },
    {
      "day": "2017-08-29",
      "value": 80
    },
    {
      "day": "2016-08-05",
      "value": 141
    },
    {
      "day": "2016-09-16",
      "value": 349
    },
    {
      "day": "2015-04-30",
      "value": 392
    },
    {
      "day": "2016-03-30",
      "value": 256
    },
    {
      "day": "2016-08-27",
      "value": 365
    },
    {
      "day": "2015-07-25",
      "value": 198
    },
    {
      "day": "2016-05-11",
      "value": 266
    },
    {
      "day": "2017-02-24",
      "value": 108
    },
    {
      "day": "2015-04-11",
      "value": 267
    },
    {
      "day": "2016-09-07",
      "value": 157
    },
    {
      "day": "2018-01-31",
      "value": 296
    },
    {
      "day": "2016-01-28",
      "value": 224
    },
    {
      "day": "2016-05-17",
      "value": 300
    },
    {
      "day": "2016-04-08",
      "value": 383
    },
    {
      "day": "2016-09-13",
      "value": 38
    },
    {
      "day": "2016-12-19",
      "value": 62
    },
    {
      "day": "2017-05-31",
      "value": 140
    },
    {
      "day": "2018-02-24",
      "value": 381
    },
    {
      "day": "2016-06-30",
      "value": 177
    },
    {
      "day": "2015-06-10",
      "value": 133
    },
    {
      "day": "2016-03-28",
      "value": 369
    },
    {
      "day": "2017-05-11",
      "value": 374
    },
    {
      "day": "2016-03-27",
      "value": 231
    },
    {
      "day": "2016-04-19",
      "value": 205
    },
    {
      "day": "2016-09-01",
      "value": 99
    },
    {
      "day": "2016-06-11",
      "value": 127
    },
    {
      "day": "2015-11-20",
      "value": 320
    },
    {
      "day": "2017-11-14",
      "value": 140
    },
    {
      "day": "2017-10-05",
      "value": 243
    },
    {
      "day": "2016-09-24",
      "value": 181
    },
    {
      "day": "2016-01-04",
      "value": 204
    },
    {
      "day": "2016-06-12",
      "value": 282
    },
    {
      "day": "2015-06-28",
      "value": 371
    },
    {
      "day": "2015-07-06",
      "value": 279
    },
    {
      "day": "2018-05-08",
      "value": 63
    },
    {
      "day": "2015-04-12",
      "value": 133
    },
    {
      "day": "2018-02-04",
      "value": 94
    },
    {
      "day": "2015-08-01",
      "value": 18
    },
    {
      "day": "2017-04-05",
      "value": 298
    },
    {
      "day": "2017-01-16",
      "value": 394
    },
    {
      "day": "2018-04-01",
      "value": 245
    },
    {
      "day": "2017-10-24",
      "value": 323
    },
    {
      "day": "2015-09-21",
      "value": 1
    },
    {
      "day": "2015-11-13",
      "value": 73
    },
    {
      "day": "2017-05-01",
      "value": 106
    },
    {
      "day": "2016-02-01",
      "value": 89
    },
    {
      "day": "2018-04-28",
      "value": 120
    },
    {
      "day": "2015-05-28",
      "value": 378
    },
    {
      "day": "2017-03-14",
      "value": 343
    },
    {
      "day": "2015-11-04",
      "value": 177
    },
    {
      "day": "2016-10-25",
      "value": 206
    },
    {
      "day": "2017-04-03",
      "value": 257
    },
    {
      "day": "2015-12-04",
      "value": 320
    },
    {
      "day": "2017-04-02",
      "value": 57
    },
    {
      "day": "2017-10-29",
      "value": 306
    },
    {
      "day": "2017-05-12",
      "value": 187
    },
    {
      "day": "2016-06-07",
      "value": 217
    },
    {
      "day": "2018-02-08",
      "value": 346
    },
    {
      "day": "2018-03-15",
      "value": 247
    },
    {
      "day": "2015-10-23",
      "value": 184
    },
    {
      "day": "2017-05-15",
      "value": 324
    },
    {
      "day": "2017-05-08",
      "value": 121
    },
    {
      "day": "2017-03-20",
      "value": 286
    },
    {
      "day": "2016-03-12",
      "value": 272
    },
    {
      "day": "2017-03-04",
      "value": 105
    },
    {
      "day": "2016-05-24",
      "value": 221
    },
    {
      "day": "2016-12-15",
      "value": 245
    },
    {
      "day": "2015-05-05",
      "value": 48
    },
    {
      "day": "2018-08-06",
      "value": 205
    },
    {
      "day": "2016-11-12",
      "value": 341
    },
    {
      "day": "2018-04-18",
      "value": 217
    },
    {
      "day": "2016-05-08",
      "value": 151
    },
    {
      "day": "2017-12-27",
      "value": 254
    },
    {
      "day": "2017-12-01",
      "value": 34
    },
    {
      "day": "2017-04-07",
      "value": 44
    },
    {
      "day": "2017-07-26",
      "value": 299
    },
    {
      "day": "2016-11-03",
      "value": 203
    },
    {
      "day": "2015-04-23",
      "value": 273
    },
    {
      "day": "2017-08-24",
      "value": 273
    },
    {
      "day": "2017-05-21",
      "value": 16
    },
    {
      "day": "2018-02-11",
      "value": 199
    },
    {
      "day": "2015-09-26",
      "value": 30
    },
    {
      "day": "2016-02-07",
      "value": 102
    },
    {
      "day": "2017-02-16",
      "value": 7
    },
    {
      "day": "2018-01-18",
      "value": 223
    },
    {
      "day": "2015-07-24",
      "value": 65
    },
    {
      "day": "2016-08-21",
      "value": 286
    },
    {
      "day": "2015-08-21",
      "value": 157
    },
    {
      "day": "2018-03-25",
      "value": 85
    },
    {
      "day": "2017-01-20",
      "value": 277
    },
    {
      "day": "2015-08-16",
      "value": 234
    },
    {
      "day": "2016-01-30",
      "value": 216
    },
    {
      "day": "2015-06-04",
      "value": 303
    },
    {
      "day": "2016-05-26",
      "value": 250
    },
    {
      "day": "2016-05-01",
      "value": 345
    },
    {
      "day": "2017-11-12",
      "value": 35
    },
    {
      "day": "2018-08-09",
      "value": 166
    },
    {
      "day": "2017-10-31",
      "value": 115
    },
    {
      "day": "2017-02-04",
      "value": 330
    },
    {
      "day": "2016-04-22",
      "value": 322
    },
    {
      "day": "2016-01-18",
      "value": 316
    },
    {
      "day": "2017-07-16",
      "value": 91
    },
    {
      "day": "2015-12-13",
      "value": 126
    },
    {
      "day": "2017-12-22",
      "value": 316
    },
    {
      "day": "2015-08-11",
      "value": 224
    },
    {
      "day": "2015-04-20",
      "value": 392
    },
    {
      "day": "2016-05-22",
      "value": 377
    },
    {
      "day": "2015-11-10",
      "value": 25
    },
    {
      "day": "2016-02-02",
      "value": 153
    },
    {
      "day": "2016-04-13",
      "value": 54
    },
    {
      "day": "2015-10-13",
      "value": 124
    },
    {
      "day": "2015-09-06",
      "value": 2
    },
    {
      "day": "2017-05-20",
      "value": 150
    },
    {
      "day": "2017-08-10",
      "value": 18
    },
    {
      "day": "2018-05-30",
      "value": 68
    },
    {
      "day": "2018-03-19",
      "value": 66
    },
    {
      "day": "2015-05-07",
      "value": 309
    },
    {
      "day": "2016-09-28",
      "value": 52
    },
    {
      "day": "2016-02-20",
      "value": 314
    },
    {
      "day": "2017-04-30",
      "value": 108
    },
    {
      "day": "2017-05-16",
      "value": 300
    },
    {
      "day": "2016-12-08",
      "value": 146
    },
    {
      "day": "2018-05-20",
      "value": 201
    },
    {
      "day": "2015-10-17",
      "value": 358
    },
    {
      "day": "2018-07-07",
      "value": 5
    },
    {
      "day": "2015-10-16",
      "value": 139
    },
    {
      "day": "2016-02-24",
      "value": 263
    },
    {
      "day": "2017-04-13",
      "value": 45
    },
    {
      "day": "2017-04-10",
      "value": 218
    },
    {
      "day": "2017-04-19",
      "value": 275
    },
    {
      "day": "2016-05-15",
      "value": 357
    },
    {
      "day": "2017-12-20",
      "value": 105
    },
    {
      "day": "2016-10-31",
      "value": 196
    },
    {
      "day": "2015-07-30",
      "value": 111
    },
    {
      "day": "2016-02-25",
      "value": 284
    },
    {
      "day": "2016-08-18",
      "value": 163
    },
    {
      "day": "2018-01-21",
      "value": 231
    },
    {
      "day": "2015-11-17",
      "value": 299
    },
    {
      "day": "2016-10-05",
      "value": 316
    },
    {
      "day": "2017-04-22",
      "value": 351
    },
    {
      "day": "2015-10-14",
      "value": 350
    },
    {
      "day": "2018-06-29",
      "value": 365
    },
    {
      "day": "2017-09-13",
      "value": 379
    },
    {
      "day": "2017-03-02",
      "value": 143
    },
    {
      "day": "2015-06-16",
      "value": 134
    },
    {
      "day": "2018-03-06",
      "value": 286
    },
    {
      "day": "2017-10-27",
      "value": 323
    },
    {
      "day": "2017-01-19",
      "value": 286
    },
    {
      "day": "2015-09-15",
      "value": 204
    },
    {
      "day": "2016-08-14",
      "value": 320
    },
    {
      "day": "2015-12-17",
      "value": 385
    },
    {
      "day": "2017-10-15",
      "value": 245
    },
    {
      "day": "2017-05-30",
      "value": 200
    },
    {
      "day": "2015-04-28",
      "value": 117
    },
    {
      "day": "2016-09-22",
      "value": 359
    },
    {
      "day": "2015-05-25",
      "value": 274
    },
    {
      "day": "2018-01-14",
      "value": 294
    },
    {
      "day": "2018-01-09",
      "value": 265
    },
    {
      "day": "2015-05-18",
      "value": 372
    },
    {
      "day": "2015-10-21",
      "value": 43
    },
    {
      "day": "2016-10-26",
      "value": 174
    },
    {
      "day": "2015-09-27",
      "value": 100
    },
    {
      "day": "2017-08-16",
      "value": 352
    },
    {
      "day": "2015-08-26",
      "value": 158
    },
    {
      "day": "2016-11-06",
      "value": 321
    },
    {
      "day": "2015-10-20",
      "value": 210
    },
    {
      "day": "2016-07-04",
      "value": 100
    },
    {
      "day": "2017-06-17",
      "value": 220
    },
    {
      "day": "2015-06-01",
      "value": 335
    },
    {
      "day": "2017-08-07",
      "value": 70
    },
    {
      "day": "2018-06-17",
      "value": 178
    },
    {
      "day": "2015-08-13",
      "value": 368
    },
    {
      "day": "2015-08-28",
      "value": 303
    },
    {
      "day": "2015-06-05",
      "value": 47
    },
    {
      "day": "2017-06-16",
      "value": 398
    },
    {
      "day": "2017-01-15",
      "value": 315
    },
    {
      "day": "2016-07-21",
      "value": 273
    },
    {
      "day": "2016-01-17",
      "value": 216
    },
    {
      "day": "2016-12-13",
      "value": 123
    },
    {
      "day": "2017-02-20",
      "value": 252
    },
    {
      "day": "2015-06-30",
      "value": 381
    },
    {
      "day": "2018-03-27",
      "value": 101
    },
    {
      "day": "2016-05-04",
      "value": 275
    },
    {
      "day": "2018-07-26",
      "value": 391
    },
    {
      "day": "2016-08-30",
      "value": 331
    },
    {
      "day": "2017-01-30",
      "value": 154
    },
    {
      "day": "2017-01-09",
      "value": 199
    },
    {
      "day": "2016-08-17",
      "value": 389
    },
    {
      "day": "2016-11-05",
      "value": 371
    },
    {
      "day": "2016-03-09",
      "value": 356
    },
    {
      "day": "2017-06-15",
      "value": 389
    },
    {
      "day": "2015-11-12",
      "value": 191
    },
    {
      "day": "2015-07-20",
      "value": 204
    },
    {
      "day": "2018-03-30",
      "value": 67
    },
    {
      "day": "2015-11-01",
      "value": 129
    },
    {
      "day": "2016-05-20",
      "value": 290
    },
    {
      "day": "2015-09-14",
      "value": 226
    },
    {
      "day": "2015-09-05",
      "value": 101
    },
    {
      "day": "2015-08-20",
      "value": 38
    },
    {
      "day": "2018-07-17",
      "value": 245
    },
    {
      "day": "2015-05-10",
      "value": 249
    },
    {
      "day": "2017-11-05",
      "value": 175
    },
    {
      "day": "2016-06-13",
      "value": 349
    },
    {
      "day": "2015-05-30",
      "value": 320
    },
    {
      "day": "2017-01-12",
      "value": 125
    },
    {
      "day": "2017-03-30",
      "value": 355
    },
    {
      "day": "2016-07-30",
      "value": 322
    },
    {
      "day": "2016-08-13",
      "value": 362
    },
    {
      "day": "2017-12-16",
      "value": 159
    },
    {
      "day": "2017-11-30",
      "value": 282
    },
    {
      "day": "2015-10-24",
      "value": 262
    },
    {
      "day": "2015-08-24",
      "value": 250
    },
    {
      "day": "2017-02-19",
      "value": 17
    },
    {
      "day": "2018-07-19",
      "value": 51
    },
    {
      "day": "2016-10-06",
      "value": 203
    },
    {
      "day": "2015-10-02",
      "value": 26
    },
    {
      "day": "2018-05-04",
      "value": 101
    },
    {
      "day": "2016-02-05",
      "value": 307
    },
    {
      "day": "2018-05-13",
      "value": 73
    },
    {
      "day": "2018-04-12",
      "value": 335
    },
    {
      "day": "2017-07-28",
      "value": 261
    },
    {
      "day": "2017-11-28",
      "value": 353
    },
    {
      "day": "2015-06-14",
      "value": 51
    },
    {
      "day": "2018-06-20",
      "value": 75
    },
    {
      "day": "2018-08-03",
      "value": 41
    },
    {
      "day": "2016-12-24",
      "value": 389
    },
    {
      "day": "2016-11-13",
      "value": 156
    },
    {
      "day": "2016-12-12",
      "value": 334
    },
    {
      "day": "2015-11-18",
      "value": 322
    },
    {
      "day": "2018-07-22",
      "value": 76
    },
    {
      "day": "2018-04-22",
      "value": 329
    },
    {
      "day": "2016-09-17",
      "value": 171
    },
    {
      "day": "2018-05-10",
      "value": 384
    },
    {
      "day": "2017-11-02",
      "value": 175
    },
    {
      "day": "2015-07-07",
      "value": 108
    },
    {
      "day": "2016-06-27",
      "value": 12
    },
    {
      "day": "2016-09-20",
      "value": 325
    },
    {
      "day": "2016-01-31",
      "value": 384
    },
    {
      "day": "2018-02-25",
      "value": 63
    },
    {
      "day": "2018-03-03",
      "value": 269
    }
  ];