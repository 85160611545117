import React from "react";
import {
  withStyles,
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton
} from "@material-ui/core/";

import { ArrowBack, Menu } from "@material-ui/icons/";

import { withContext } from "../contexts/ApplicationContext.jsx";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  offset: theme.mixins.toolbar
});

class XilentNavigationBar extends React.Component {
  constructor(props) {
    super(props);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    var current = this.props.context.state.isOpened;
    this.props.context.updateValue("isOpened", !current);
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <AppBar>
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
            >
              {this.props.context.state.isOpened ? (
                <ArrowBack onClick={this.toggleMenu} />
              ) : (
                <Menu onClick={this.toggleMenu} />
              )}
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {this.props.context.state.selectedPage.title}
            </Typography>
            <Button color="inherit">ПРИВЕТОС, АГАДА</Button>
          </Toolbar>
        </AppBar>
        <div className={classes.offset} />
      </div>
    );
  }
}

export default withStyles(styles)(withContext(XilentNavigationBar));
